import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { mediaMin } from '../../../utils/css';
import { StyledCarousel as Carousel } from '../../Carousel';
import { usePageContext } from '../../PageEmbeddingContext.jsx';
import {
  MoreOnTopStoryContentTeaser,
  moreOnTopStoryTeaserFragment,
  darkMoreOnTopStoryContentTeaserStyles,
} from './MoreOnTopStoryContentTeaser.jsx';
import { StyledContentBlockHeadline as ContentBlockHeadline } from '../ContentBlockHeadline.jsx';
import { StyledHiddenHeadline as HiddenHeadline } from '../../HiddenHeadline.jsx';
import { StyledA11ySection as A11ySection } from '../../a11y/A11ySection.jsx';
import { metaBackground, metaGradient } from '../../../utils/gradientCss';
import { StyledTopStoryHeroImage as TopStoryHeroImage } from './TopStoryHeroImage.jsx';
import { StyledTopStoryContentTeaserData as TopStoryContentTeaserData } from './TopStoryContentTeaserData.jsx';
import { StyledTopStoryContentTeaserTitle as TopStoryContentTeaserTitle, topStoryTitleMargin } from './TopStoryContentTeaserTitle.jsx';
import { commonBlockPadding, contentBlockWidth } from '../ContentBlock';
import { isValidList } from '../../../utils/contentUtils';
import { makeMemoFragment } from '../../../utils/graphql';

import { StyledContentStatusTeaserBadge as ContentStatusTeaserBadge } from '../../ContentTeaserSnippets/ContentTeaserPartials/Partials/ContentStatusTeaserBadge.jsx';
import { TopStoryTeaserContextProvider } from './TopStoryTeaserContextProvider.jsx';
import { CarouselContextProvider } from '../../Carousel/CarouselContext.jsx';

// Do not generate superfluous fragment, top story main content needs
// same data as teasers but simply displays those differently
export const topStoryFragment = makeMemoFragment({
  name: 'TopStory',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on ModelAspect {
          id
        }
        ...${moreOnTopStoryTeaserFragment.name}
      }
      ${moreOnTopStoryTeaserFragment.fragment()}
    `;
  },
});

export const TopStory = ({
  className, content = {}, moreStoryContents = [], pageSectionId,
}) => {
  const hasMoreOnTopStory = isValidList(moreStoryContents);
  const pageContext = usePageContext();

  const homeTopStoryTranslation = 'content_block_detail.home_top_story.headline';
  const topStoryTranslation = 'content_block.top_story.headline';

  const contentBlockHeadline = (
    pageContext.isOnDetailPage
      ? <ContentBlockHeadline isA="h1" translation={homeTopStoryTranslation} className={cx('headline', commonBlockPadding)}/>
      : <HiddenHeadline isA="h2" translation={topStoryTranslation}/>
  );
  return (
    <A11ySection
      className={className}
      pageSectionId={pageSectionId}
      sectionTitle={pageContext.isOnDetailPage ? homeTopStoryTranslation : topStoryTranslation}
    >
      {contentBlockHeadline}
      <TopStoryTeaserContextProvider content={content}>
        <TopStoryHeroImage className='ts-hero' />
        <div className={cx('ts-teaser-title', commonBlockPadding)}>
          <ContentStatusTeaserBadge />
          <TopStoryContentTeaserTitle />
        </div>
        <TopStoryContentTeaserData className={cx('ts-teaser-data', commonBlockPadding)}/>
      </TopStoryTeaserContextProvider>
      <div className='ts-gradient'/>
      <div className='ts-background'/>
      {hasMoreOnTopStory && (
        <CarouselContextProvider>
          <Carousel className={cx('carousel', darkMoreOnTopStoryContentTeaserStyles)} >
            {moreStoryContents.map(moreStoryContent => (
              <MoreOnTopStoryContentTeaser
                key={moreStoryContent.id}
                content={moreStoryContent}
                titleIsA={pageContext.isOnDetailPage ? 'h3' : 'h4'}
              />
            ))}
          </Carousel>
        </CarouselContextProvider>
      )}
    </A11ySection>
  );
};

export const StyledTopStory = styled(TopStory)`
  display: grid;
  grid-template-rows: repeat(5, min-content);
  grid-template-columns: [content-block-start] auto [content-block-end];

  .headline {
    padding-top: 25px;
    overflow: hidden;
  }

  .headline {
    grid-row: 1;
    grid-column: content-block;
  }
  .ts-hero {
    grid-row: 2 / -2;
    grid-column: 1 / -1;
  }
  .ts-teaser-title {
    grid-row: 3;
    grid-column: content-block;
    z-index: 3;
    ${topStoryTitleMargin}
  }
  .ts-teaser-data {
    grid-row: 4;
    grid-column: content-block;
    z-index: 3;
    padding-bottom: 15px;
  }
  .ts-gradient {
    pointer-events: none;
    ${metaGradient}
    z-index: 2;
    grid-row: 3;
    grid-column: 1 / -1;
  }
  .ts-background {
    ${metaBackground}
    z-index: 2;
    grid-row: 4;
    grid-column: 1 / -1;
  }
  .carousel {
    grid-row: 5;
    grid-column: content-block;
  }

  ${mediaMin.lg`
  grid-template-rows: repeat(6, min-content);
  grid-template-columns: 1fr [content-block-start] ${contentBlockWidth.lg}px [content-block-end] 1fr;

  .ts-background {
    grid-row: 4 / -2;
  }
  .carousel {
    grid-row: 5 / -1;
    z-index: 3;
  }

  `}

  ${mediaMin.xl`
  grid-template-columns: 1fr [content-block-start] ${contentBlockWidth.xl}px [content-block-end] 1fr;
  `}
`;
