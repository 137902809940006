import gql from 'graphql-tag';
import { useParams, Redirect, useLocation } from '../dwouter';
import { StyledHeader as Header, headerFragmentFactory } from '../components/Header';
import { darkContentPageLayoutStyles, StyledContentPageLayout as ContentPageLayout } from '../components/layouts/ContentPageLayout.jsx';
import { StyledFooter as Footer, footerFragmentFactory } from '../components/Footer';
import { StyledQuickNavigationList as QuickNavigationList } from '../components/a11y/QuickNavigationList.jsx';
import { PageWithData, pageWithDataFragment } from './PageWithData.jsx';
import { GtmScriptWithDataLayer, gtmDataLayerFragment } from '../components/GoogleTagManager';
import { PageHeaderMetadata, pageHeaderMetadataFragment } from '../components/PageHeaderMetadata.jsx';
import { withTheme } from '../components/higherOrderComponents/withTheme.jsx';
import { StyledVendorSettings as VendorSettings, vendorSettingsFragment } from '../components/VendorSettings.jsx';
import { isPathMatching } from '../components/commons/PathAnalyser';

export const vendorConsentQuery = () => {
  const headerFragment = headerFragmentFactory();
  const footerFragment = footerFragmentFactory();
  return gql`
  query getVendorConsentData($lang: Language!) {
    privacySettings(lang: $lang){
      id: originId
      namedUrl
      ...${pageWithDataFragment.name}
      ...${headerFragment.name}
      ...${footerFragment.name}
      ...${pageHeaderMetadataFragment.name}
      ...${gtmDataLayerFragment.name}
      ...${vendorSettingsFragment.name}
    }
  }
  ${pageWithDataFragment.fragment()}
  ${gtmDataLayerFragment.fragment()}
  ${headerFragment.fragment()}
  ${footerFragment.fragment()}
  ${pageHeaderMetadataFragment.fragment()}
  ${vendorSettingsFragment.fragment()}
`;
};

export const VendorConsentPageNoQuery = ({
  privacySettings, className,
}) => (
  <>
    <PageHeaderMetadata content={privacySettings} />
    <GtmScriptWithDataLayer content={privacySettings} />
    <QuickNavigationList content={privacySettings} />
    <div className={className}>
      <Header content={privacySettings}></Header>
      <ContentPageLayout
        DetailZoneFn={
          () => <VendorSettings privacySettings={privacySettings} />
        }
      />
      <Footer content={privacySettings} />
    </div>
  </>
);

export const VendorConsentPageNoQueryWithTheme = withTheme(VendorConsentPageNoQuery)(darkContentPageLayoutStyles);

export const VendorConsentPage = () => {
  const { langCode } = useParams();
  const queryDef = {
    path: `${langCode}/vendor-consent`,
    depts: [langCode],
  };
  const [{ pathname, search }] = useLocation();

  return (
    <PageWithData queryDef={queryDef} langBaseContentFn={data => data.privacySettings} >
      {({ privacySettings }) => {
        if (!isPathMatching({ namedUrl: privacySettings.namedUrl, pathname })) {
          return <Redirect to={{ pathname: privacySettings.namedUrl, search }} />;
        }
        return (
          <VendorConsentPageNoQueryWithTheme privacySettings={privacySettings}/>
        );
      }
      }
    </PageWithData>
  );
};
