import gql from 'graphql-tag';

import { makeMemoFragment } from '../../../utils/graphql';

import { StyledAVCarouselTeaser as AVCarouselTeaser, avCarouselTeaserFragment, darkAVCarouselTeaserStyles } from './AVCarouselTeaser.jsx';
import { listCarouselFragment, StyledListCarousel as ListCarouselTemplate } from '../../ContentTeaserSnippets/Templates/ListCarousel.jsx';
import { extendWithClassNames } from '../../../utils/extendWithClassNames.jsx';
import { ITEMS_PER_SLIDE_CONFIG, CarouselContextProvider } from '../../Carousel/CarouselContext.jsx';
import { featuredCarouselTeaserContainerClassName, carouselTeaserContainerClassName } from '../../ContentTeaserSnippets/ContentTeaserPartials/CarouselTeaserContainer.jsx';

export const avCarouselFragment = makeMemoFragment({
  name: 'AvCarousel',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ...${listCarouselFragment.name}
        ...${avCarouselTeaserFragment.name}
      }
      ${listCarouselFragment.fragment()}
      ${avCarouselTeaserFragment.fragment()}
    `;
  },
});

export const AVCarousel = ({ isFeatured, ...restProps }) => (
  <CarouselContextProvider itemsPerSlideConfig={isFeatured ? ITEMS_PER_SLIDE_CONFIG.featured : ITEMS_PER_SLIDE_CONFIG.default}>
    <ListCarouselTemplate
      {...restProps}
      teaserIsA={AVCarouselTeaser}
      teaserContainerClassName={isFeatured ? featuredCarouselTeaserContainerClassName : carouselTeaserContainerClassName}
    />
  </CarouselContextProvider>
);

export const StyledAVCarousel = extendWithClassNames(AVCarousel)(
  darkAVCarouselTeaserStyles,
);
