import { suspendedComponentFactory } from '../SuspendedComponent/suspendedComponentFactory.jsx';

export { StyledAudio } from './mediaTag/components/Audio.jsx';
export { StyledVideo } from './mediaTag/components/Video.jsx';
export { StyledGallery } from './mediaTag/components/Gallery.jsx';
export { StyledInfographics } from './mediaTag/components/Infographics.jsx';
export { StyledInteractive } from './mediaTag/components/Interactive.jsx';

export * from './socialMedia/components';

export { StyledBurger } from './Burger.jsx';
export { StyledClose } from './Close.jsx';
export { StyledDownload } from './Download.jsx';
export { StyledLogoWhite } from './DwLogoWhite.jsx';
export { StyledLogoDarkBlue } from './DwLogoDarkBlue.jsx';
export { StyledExternalLink } from './ExternalLink.jsx';
export { StyledFeedback } from './Feedback.jsx';
export { StyledChevronOpenClose, StyledChevron } from './Chevron.jsx';
export { StyledLeftChevron } from './LeftChevron.jsx';
export { StyledRightChevron } from './RightChevron.jsx';
export { StyledSliderPositionDot } from './SliderPositionDot.jsx';
export { StyledSpinnerIcon } from './SpinnerIcon.jsx';
export { StyledGlobe } from './Globe.jsx';
export { StyledReturn } from './Return.jsx';
export { StyledChainLink } from './ChainLink.jsx';
export const { StyledCheck } = suspendedComponentFactory(() => import('./Check.jsx'));
